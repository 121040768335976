import {ReactElement, useState} from 'react';

// @ts-ignore
import InfoIcon from "@/img/icon/info-icon.svg"

interface TooltipIconProps {
    text: string | undefined;
}

const TooltipIcon = ({ text }: TooltipIconProps): ReactElement | null => {
    const [isVisible, setIsVisible] = useState(false);

    if (!text) return null;

    return (
        <span
            style={{
                marginLeft: '8px',
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'help',
                position: 'relative',
                top: '-1px'
            }}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
        <img
            src={InfoIcon}
            alt="info"
            width="16"
            height="16"
            style={{
                transition: 'all 0.2s ease'
            }}
        />
            {isVisible && (
                <div style={{
                    position: 'absolute',
                    left: '24px',
                    top: '-4px',
                    backgroundColor: '#6B6F75',
                    color: '#F3F4F6',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    fontSize: '10px',
                    minWidth: '300px',
                    maxWidth: '500px',
                    zIndex: 9999,
                    whiteSpace: 'normal',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                    {text}
                </div>
            )}
    </span>
    );
};

export default TooltipIcon;
